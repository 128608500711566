import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';

function Location({ id, top, left, count, name }) {
  const [isHovering, setIsHovering] = useState(false);
  return (
    <Link href={`/location/${id}`}>
      <a
        style={{ top: `${top}%`, left: `${left}%` }}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        className={`c-absolute c-hidden md:c-flex c-w-2 c-h-2 c-flex-col items-center ${
          isHovering ? 'c-z-[100]' : ''
        }`}>
        <div className="c-count c-z-50">{count}</div>
        <div className="c-flex c-items-center c-absolute c-bg-blue c-w-3 c-h-3 c-rounded-full c-z-10 c--top-1 c-animate-pulse-map">
          <div className="c-absolute c-bg-mapMarkCore c-w-2 c-h-2 c-rounded-full c-inset-0 c-m-auto c-z" />
        </div>
        <div
          className={`c-name lbl-austin c-flex c-z-[60] c-transition-opacity c-duration-700 c-ease-out ${
            isHovering ? 'hover:c-opacity-100' : 'c-opacity-0'
          }`}>
          {name}
        </div>
      </a>
    </Link>
  );
}

Location.propTypes = {
  id: PropTypes.string.isRequired,
  top: PropTypes.number.isRequired,
  left: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
};

export default Location;
