import {
  getDepartments,
  getPosition,
  getDepartment,
  getAllJobs,
  getLocations,
} from './api';
import sanitizeHtml from 'sanitize-html';
import { decode } from 'html-entities';
import { buildSlug } from './utils';
import useTranslation from 'next-translate/useTranslation';

// px to have the SubNav sticky
export const getSubNavMenuSticky = 910;

export async function getDataPosition(positionId) {
  if (positionId === '<no source>') {
    return;
  }
  const data = await getPosition(positionId);

  // Return empty data if no results found
  if (Object.keys(data).length === 0) {
    return [];
  }

  const metadata = getMetadata(data.metadata);
  const city = getCity(data.location.name);
  const department = data.departments[0].name;
  const departmentId = data.departments[0].id;
  const departmentKey = department
    .replace(/[^\w\s-]/gi, '')
    .replace(/\s+/g, ' ')
    .replace(/\s/g, '-')
    .toLowerCase();
  return {
    title: data.title,
    content: sanitizeHtml(decode(data.content)),
    location: data.location.name,
    requisitionId: data.requisition_id,
    department,
    departmentId,
    departmentKey,
    type: metadata.type,
    region: metadata.region,
    city: city.toLocaleString(),
    id: data.id,
  };
}

function getMetadata(metadata) {
  return metadata.reduce((acc, item) => {
    if (item.name === 'Worker Sub-Type') {
      if (item.value === 'Contractor') {
        return { ...acc, type: 'Contract' };
      } else if (item.value?.startsWith('Intern')) {
        return { ...acc, type: 'Intern' };
      } else if (item.value?.startsWith('Student')) {
        return { ...acc, type: 'Student' };
      } else {
        return { ...acc, type: 'Full-time' };
      }
    }
    if (item.name !== 'Worker Sub-Type') {
      return { ...acc, [item.name.toLowerCase()]: item.value };
    }
  }, {});
}

function getCity(location) {
  return location.split(',')[0];
}

export async function getJobList() {
  const data = await getAllJobs();

  // Return empty data if no results found
  if (Object.keys(data).length === 0) {
    return [];
  }

  return data.jobs;
}

/**
 * Function that provides all departments with jobs
 * @returns Department-jobs list
 */
export async function getAllDepartmentsJobs() {
  const data = await getDepartments();
  let departmentLabels = new Map();
  let locationLabels = new Map();
  let typeLabels = new Map();
  let totalPositions = 0;

  // Return empty data if no results found
  if (Object.keys(data).length === 0) {
    return {
      departments: [],
      locationLabels: [],
      departmentLabels: [],
      typeLabels: [],
      totalPositions: [],
    };
  }

  return {
    departments: data.departments
      .filter(department => department.jobs.length > 0)
      .map(department => {
        const departmentKey = buildSlug(department.name);
        const jobs = department.jobs.map(job => {
          const locationId = buildSlug(job.location.name.split(',')[0]);
          locationLabels.set(locationId, {
            id: locationId,
            label: job.location.name.split(',')[0],
            count: locationLabels.has(locationId)
              ? locationLabels.get(locationId).count + 1
              : 1,
          });
          const metadata = getMetadata(job.metadata);
          typeLabels.set(metadata.type, {
            id: metadata.type,
            label: metadata.type,
          });
          return {
            id: job.id,
            title: job.title,
            location: job.location.name,
            locationId,
            type: metadata.type,
          };
        });
        // Build department information
        departmentLabels.set(department.name, {
          id: departmentKey,
          label: department.name,
          count: jobs.length,
        });
        totalPositions += jobs.length;
        return {
          id: department.id,
          key: departmentKey,
          name: department.name,
          jobs: jobs,
        };
      }),
    locationLabels,
    departmentLabels,
    typeLabels,
    totalPositions,
  };
}

/**
 * Function that provides a departments jobs
 * @returns Department-jobs list
 */
export async function getDepartmentJobs(departmentId, excludeIds = []) {
  const data = await getDepartment(departmentId);
  let locationLabels = new Map();

  // Return empty data if no results found
  if (Object.keys(data).length === 0) {
    return [];
  }

  return {
    departments: [
      {
        id: departmentId,
        key: buildSlug(data.name),
        name: data.name,
        jobs: data.jobs
          .filter(job => !excludeIds.includes(job.id))
          .map(job => {
            const locationId = buildSlug(job.location.name.split(',')[0]);
            locationLabels.set(locationId, {
              id: locationId,
              label: job.location.name.split(',')[0],
              count: locationLabels.has(locationId)
                ? locationLabels.get(locationId).count + 1
                : 1,
            });
            const metadata = getMetadata(job.metadata);
            return {
              id: job.id,
              title: job.title,
              location: job.location.name,
              locationId,
              type: metadata.type,
            };
          }),
      },
    ],
  };
}
/**
 * Function that provides a office locations
 * @returns Location-offices list
 */
export async function getOfficeLocations() {
  const data = await getLocations();
  const locationIdToAdd = 'remote';

  let locationOffices = new Map();
  // Return empty data if no results found
  if (Object.keys(data).length === 0) {
    return [];
  }

  data.offices
    .filter(office => {
      return office.location !== null && !office.location.includes('Remote');
    })
    .map(office => {
      const locationId = buildSlug(office.location.split(',')[0]);

      locationOffices.set(locationId, {
        label: office.location.split(',')[0],
      });
    });
  locationOffices.set(locationIdToAdd, {
    label: 'Remote',
  });
  return locationOffices;
}

export function getMapMarkers() {
  return [
    {
      name: 'Austin',
      key: 'austin',
      top: 43.5,
      left: 14.7,
    },
    {
      name: 'Beijing',
      key: 'beijing',
      top: 32.9,
      left: 85.2,
    },
    {
      name: 'Bellevue',
      key: 'bellevue',
      top: 22.1,
      left: 8.6,
    },
    {
      name: 'Berlin',
      key: 'berlin',
      top: 22.8,
      left: 51.1,
    },
    {
      name: 'Brighton',
      key: 'brighton',
      top: 24,
      left: 46.9,
    },
    {
      name: 'Pereira',
      key: 'pereira',
      top: 73.8,
      left: 21.9,
    },
    {
      name: 'Copenhagen',
      key: 'copenhagen',
      top: 18.2,
      left: 50.7,
    },
    {
      name: 'Ghent',
      key: 'ghent',
      top: 22.3,
      left: 50.4,
    },
    {
      name: 'Helsinki',
      key: 'helsinki',
      top: 11.4,
      left: 54.3,
    },
    {
      name: 'India',
      key: 'india',
      top: 53,
      left: 73.7,
    },
    {
      name: 'Kaunas',
      key: 'kaunas',
      top: 18.1,
      left: 53.9,
    },
    {
      name: 'London',
      key: 'london',
      top: 22.2,
      left: 47.2,
    },
    {
      name: 'Southampton',
      key: 'southampton',
      top: 22.5,
      left: 46.2,
    },
    {
      name: 'Los Angeles',
      key: 'los-angeles',
      top: 38.8,
      left: 8.3,
    },
    {
      name: 'Montreal',
      key: 'montreal',
      top: 27.3,
      left: 24.4,
    },
    {
      name: 'Moscow',
      key: 'moscow',
      top: 18.1,
      left: 57.5,
    },
    {
      name: 'Odessa',
      key: 'odessa',
      top: 28.7,
      left: 56.6,
    },
    {
      name: 'Paris',
      key: 'paris',
      top: 25.3,
      left: 48.4,
    },
    {
      name: 'Pittsburgh',
      key: 'pittsburgh',
      top: 33.3,
      left: 22.1,
    },
    {
      name: 'San Francisco',
      key: 'san-francisco',
      top: 33.8,
      left: 7.3,
    },
    {
      name: 'Seoul',
      key: 'seoul',
      top: 36.3,
      left: 89.1,
    },
    {
      name: 'Shanghai',
      key: 'shanghai',
      top: 42.5,
      left: 87.4,
    },
    {
      name: 'Singapore',
      key: 'singapore',
      top: 72.5,
      left: 82.5,
    },
    {
      name: 'Portland',
      key: 'portland',
      top: 25.1,
      left: 7.9,
    },
    {
      name: 'Stockholm',
      key: 'stockholm',
      top: 12.9,
      left: 52.1,
    },
    {
      name: 'Stratford Upon Avon',
      key: 'stratford-upon-avon',
      top: 21.7,
      left: 46.4,
    },
    {
      name: 'Tokyo',
      key: 'tokyo',
      top: 36.5,
      left: 93.2,
    },
    {
      name: 'Vancouver',
      key: 'vancouver',
      top: 20.2,
      left: 8.5,
    },
    {
      name: 'Vietnam',
      key: 'vietnam',
      top: 62.5,
      left: 83.5,
    },
    {
      name: 'Vilnius',
      key: 'vilnius',
      top: 19.7,
      left: 54.4,
    },
  ];
}

export function GetFaqSections() {
  const { t } = useTranslation('careers');
  return [
    {
      id: 'sectionOne',
      title: '',
      questions: [
        {
          question: t`FAQ_SECTION.QUESTION_1.TITLE`,
          answer: t`FAQ_SECTION.QUESTION_1.TEXT`,
        },
        {
          question: t`FAQ_SECTION.QUESTION_2.TITLE`,
          answer: t`FAQ_SECTION.QUESTION_2.TEXT`,
        },
        {
          question: t`FAQ_SECTION.QUESTION_3.TITLE`,
          answer: t`FAQ_SECTION.QUESTION_3.TEXT`,
        },
        {
          question: t`FAQ_SECTION.QUESTION_4.TITLE`,
          answer: t`FAQ_SECTION.QUESTION_4.TEXT`,
        },
        {
          question: t`FAQ_SECTION.QUESTION_5.TITLE`,
          answer: t`FAQ_SECTION.QUESTION_5.TEXT`,
        },
        {
          question: t`FAQ_SECTION.QUESTION_5_5.TITLE`,
          answer: t`FAQ_SECTION.QUESTION_5_5.TEXT`,
        },
      ],
    },
    {
      id: 'sectionTwo',
      title: '',
      questions: [
        {
          question: t`FAQ_SECTION.QUESTION_6.TITLE`,
          answer: t`FAQ_SECTION.QUESTION_6.TEXT`,
        },
        {
          question: t`FAQ_SECTION.QUESTION_7.TITLE`,
          answer: t`FAQ_SECTION.QUESTION_7.TEXT`,
        },
        {
          question: t`FAQ_SECTION.QUESTION_8.TITLE`,
          answer: t`FAQ_SECTION.QUESTION_8.TEXT`,
        },
        {
          question: t`FAQ_SECTION.QUESTION_9.TITLE`,
          answer: t`FAQ_SECTION.QUESTION_9.TEXT`,
        },
        {
          question: t`FAQ_SECTION.QUESTION_10.TITLE`,
          answer: t`FAQ_SECTION.QUESTION_10.TEXT`,
        },
        {
          question: t`FAQ_SECTION.QUESTION_11.TITLE`,
          answer: t`FAQ_SECTION.QUESTION_11.TEXT`,
        },
      ],
    },
  ];
}

export function GetStayHappyIcons() {
  const { t } = useTranslation('careers');
  return [
    {
      icon: 'favorite_border',
      text: t`HAPPY_SECTION.ICON_1.TEXT`,
      placement: 'bottom-start',
    },
    {
      icon: 'directions_transit',
      text: t`HAPPY_SECTION.ICON_2.TEXT`,
    },
    {
      icon: 'trending_up',
      text: t`HAPPY_SECTION.ICON_3.TEXT`,
    },
    {
      icon: 'account_balance',
      text: t`HAPPY_SECTION.ICON_4.TEXT`,
    },
    {
      icon: 'mood',
      text: t`HAPPY_SECTION.ICON_5.TEXT`,
    },
    {
      icon: 'event_available',
      text: t`HAPPY_SECTION.ICON_6.TEXT`,
    },
    {
      icon: 'restaurant_menu',
      text: t`HAPPY_SECTION.ICON_7.TEXT`,
    },
    {
      icon: 'nature_people',
      text: t`HAPPY_SECTION.ICON_8.TEXT`,
    },
    {
      icon: 'group',
      text: t`HAPPY_SECTION.ICON_9.TEXT`,
    },
    {
      icon: 'public',
      text: t`HAPPY_SECTION.ICON_10.TEXT`,
    },
    {
      icon: 'local_library',
      text: t`HAPPY_SECTION.ICON_11.TEXT`,
    },
    {
      icon: 'card_giftcard',
      text: t`HAPPY_SECTION.ICON_12.TEXT`,
      placement: 'bottom-end',
    },
  ];
}

export const montrealDeparmentNames = [
  {
    id: 'Business Development & Sales',
    name: 'Développement des affaires & ventes / Business Development & Sales',
  },
  {
    id: 'Customer Experience',
    name: 'Expérience client / Customer Experience',
  },
  {
    id: 'Data & Analytics',
    name: 'Données & analyses / Data & Analytics',
  },
  {
    id: 'Design',
    name: 'Design / Design',
  },
  {
    id: 'Engineering',
    name: 'Ingénierie & développement / Engineering',
  },
  {
    id: 'Executive',
    name: 'Cadres & exécutifs / Executive',
  },
  {
    id: 'Facilities & Admin',
    name: 'Installations & administration / Facilities & Admin',
  },
  {
    id: 'Finance & Accounting',
    name: 'Finance & comptabilité / Finance & Accounting',
  },
  {
    id: 'Human Resources',
    name: 'Ressources humaines / Human Resources',
  },
  {
    id: 'IT & Security',
    name: 'TI & sécurité / IT & Security',
  },
  {
    id: 'Legal',
    name: 'Juridique / Legal',
  },
  {
    id: 'Marketing & Communications',
    name: 'Marketing & communication / Marketing & Communications',
  },
  {
    id: 'Product Management',
    name: 'Gestion de produits / Product Management',
  },
  {
    id: 'Project Management',
    name: 'Gestion de projets / Project Management',
  },
  {
    id: 'Students & Early Career',
    name: 'Étudiants et début de carrière / Students & Early Career',
  },
  {
    id: 'Testing & Training',
    name: 'Test et formation / Testing & Training',
  },
];

export function getInfoDeparments(t) {
  return {
    'business-development-sales': {
      title: t`FIND_POSITION.BUSINESS_DEVELOPMENT_SALES.TITLE`,
      subtitle: t`FIND_POSITION.BUSINESS_DEVELOPMENT_SALES.SUBTITLE`,
      description: t`FIND_POSITION.BUSINESS_DEVELOPMENT_SALES.DESCRIPTION`,
    },
    'customer-experience': {
      title: t`FIND_POSITION.CUSTOMER_EXPERIENCE.TITLE`,
      subtitle: t`FIND_POSITION.CUSTOMER_EXPERIENCE.SUBTITLE`,
      description: t`FIND_POSITION.CUSTOMER_EXPERIENCE.DESCRIPTION`,
    },
    'data-analytics': {
      title: t`FIND_POSITION.DATA_ANALYTICS.TITLE`,
      subtitle: t`FIND_POSITION.DATA_ANALYTICS.SUBTITLE`,
      description: t`FIND_POSITION.DATA_ANALYTICS.DESCRIPTION`,
    },
    design: {
      title: t`FIND_POSITION.DESIGN.TITLE`,
      subtitle: t`FIND_POSITION.DESIGN.SUBTITLE`,
      description: t`FIND_POSITION.DESIGN.DESCRIPTION`,
    },
    engineering: {
      title: t`FIND_POSITION.ENGINEERING.TITLE`,
      subtitle: t`FIND_POSITION.ENGINEERING.SUBTITLE`,
      description: t`FIND_POSITION.ENGINEERING.DESCRIPTION`,
    },
    'facilities-admin': {
      title: t`FIND_POSITION.FACILITIES_ADMIN.TITLE`,
      subtitle: t`FIND_POSITION.FACILITIES_ADMIN.SUBTITLE`,
      description: t`FIND_POSITION.FACILITIES_ADMIN.DESCRIPTION`,
    },
    'finance-accounting': {
      title: t`FIND_POSITION.FINANCE_ACCOUNTING.TITLE`,
      subtitle: t`FIND_POSITION.FINANCE_ACCOUNTING.SUBTITLE`,
      description: t`FIND_POSITION.FINANCE_ACCOUNTING.DESCRIPTION`,
    },
    'human-resources': {
      title: t`FIND_POSITION.HUMAN_RESOURCES.TITLE`,
      subtitle: t`FIND_POSITION.HUMAN_RESOURCES.SUBTITLE`,
      description: t`FIND_POSITION.HUMAN_RESOURCES.DESCRIPTION`,
    },

    'it-security': {
      title: t`FIND_POSITION.IT_SECURITY.TITLE`,
      subtitle: t`FIND_POSITION.IT_SECURITY.SUBTITLE`,
      description: t`FIND_POSITION.IT_SECURITY.DESCRIPTION`,
    },
    legal: {
      title: t`FIND_POSITION.LEGAL.TITLE`,
      subtitle: t`FIND_POSITION.LEGAL.SUBTITLE`,
      description: t`FIND_POSITION.LEGAL.DESCRIPTION`,
    },
    'marketing-communications': {
      title: t`FIND_POSITION.MARKETING_COMMUNICATIONS.TITLE`,
      subtitle: t`FIND_POSITION.MARKETING_COMMUNICATIONS.SUBTITLE`,
      description: t`FIND_POSITION.MARKETING_COMMUNICATIONS.DESCRIPTION`,
    },
    'students-early-career': {
      title: t`FIND_POSITION.STUDENTS_EARLY_CAREER.TITLE`,
      subtitle: t`FIND_POSITION.STUDENTS_EARLY_CAREER.SUBTITLE`,
      description: t`FIND_POSITION.STUDENTS_EARLY_CAREER.DESCRIPTION`,
    },
    'product-management': {
      title: t`FIND_POSITION.PRODUCT_MANAGEMENT.TITLE`,
      subtitle: t`FIND_POSITION.PRODUCT_MANAGEMENT.SUBTITLE`,
      description: t`FIND_POSITION.PRODUCT_MANAGEMENT.DESCRIPTION`,
    },
    'project-management': {
      title: t`FIND_POSITION.PROJECT_MANAGEMENT.TITLE`,
      subtitle: t`FIND_POSITION.PROJECT_MANAGEMENT.SUBTITLE`,
      description: t`FIND_POSITION.PROJECT_MANAGEMENT.DESCRIPTION`,
    },
  };
}
