import PropTypes from 'prop-types';
import useTranslation from 'next-translate/useTranslation';
import Header from '@unity-technologies/web-ui-components/header';
import UnityLogo from '../../public/static/assets/logo.svg';
import '@unity-technologies/web-ui-components/header/styles.css';

const GlobalHeader = ({
  actionButtons,
  applications,
  accountLinks,
  links,
  logoUrl,
}) => {
  const { t } = useTranslation('careers');
  const { path: homeUrl } = logoUrl;
  const sajariProjectId = process.env.NEXT_PUBLIC_SEARCH_PROJECT_ID;
  const sajariCollectionName = process.env.NEXT_PUBLIC_SEARCH_COLLECTION_NAME;

  return (
    <Header
      accountTitle="Account"
      actionButtons={actionButtons}
      applications={applications}
      accountLinks={accountLinks}
      links={links}
      theme="dark"
      logo={<UnityLogo />}
      homeUrl={homeUrl}
      hamburgerMenuLabel={t`HEADER_BLOG.HAMBURGER_LABEL`}
      moreDropdownText={t`HEADER_BLOG.MORE_LABEL`}
      hasSearch
      search={{
        filterTabs: ['All', 'Support', 'Community'],
        renderPageOf: () => {},
        renderSearchQueryInformation: function noRefCheck() {},
        title: t`HEADER_BLOG.SEARCH_TITLE`,
      }}
      sajariProjectId={sajariProjectId}
      sajariCollectionName={sajariCollectionName}
    />
  );
};

GlobalHeader.defaultProps = {
  actionButtons: [],
  applications: [],
  accountLinks: {},
  logoUrl: {},
  links: [],
};

GlobalHeader.propTypes = {
  actionButtons: PropTypes.arrayOf(PropTypes.object),
  applications: PropTypes.arrayOf(PropTypes.object),
  accountLinks: PropTypes.objectOf(PropTypes.object),
  logoUrl: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
  links: PropTypes.arrayOf(PropTypes.object),
};

export default GlobalHeader;
