import { useRouter } from 'next/router';
import Trans from 'next-translate/Trans';
import useTranslation from 'next-translate/useTranslation';
import FooterPage from '@unity-technologies/web-ui-components/components/FooterBlog/Footer';

const Footer = ({ footerLinks, navLinks }) => {
  const { homePath } = navLinks;
  const { locale, asPath } = useRouter();
  const { t } = useTranslation('careers');
  const year = new Date().getFullYear();

  const categories = [
    { title: t`SUBMENU_CAREERS.OPEN_POSITIONS`, url: '/#unity-open-positions' },
    { title: t`SUBMENU_CAREERS.LIFE_AT_UNITY`, url: '/#unity-creativity' },
    {
      title: t`SUBMENU_CAREERS.STUDENTS`,
      url: '/#unity-students',
    },
    { title: t`SUBMENU_CAREERS.BENEFITS`, url: '/#unity-happy' },
    { title: t`SUBMENU_CAREERS.FAQ`, url: '/#unity-faq' },
  ];

  const trademarksLink =
    locale === 'en'
      ? `https://unity.com/legal/trademarks`
      : `https://unity.com/${locale}/legal/trademarks`;

  const MoreInfoLink = ({ children }) => (
    <a
      href={trademarksLink}
      className="component-footer-nav__link text-gray hover:text-blue-light">
      {children}
    </a>
  );

  const urlUsFederalHealth = {
    url: 'https://health1.aetna.com/app/public/#/one/insurerCode=AETNACVS_I&brandCode=ALICSI/machine-readable-transparency-in-coverage?reportingEntityType=Third%20Party%20Administrator_94450174&lock=true',
    label: t`FOOTER_BLOG.US_FEDERAL_HEALTHCARE`,
  };

  const BodyTextHealthComponent = () => {
    return (
      <>
        <a
          className="flex c-text-blue"
          href={urlUsFederalHealth.url}
          target="_blank"
          rel="noreferrer">
          {urlUsFederalHealth.label}
        </a>
        <br></br>
      </>
    );
  };

  const copyrightBody = (
    <>
      <BodyTextHealthComponent />
      <Trans
        i18nKey="blog:FOOTER_BLOG.COPYRIGHT_BODY"
        components={{ more_info_link: <MoreInfoLink /> }}
      />
    </>
  );

  const localizedText = {
    homePath: `${locale}`,
    currentPath: asPath,
    copyrightBody,
    categoriesNav: t`FOOTER_BLOG.CATEGORIES_NAV`,
    social: t`FOOTER_BLOG.SOCIAL_LABEL`,
    partners: t`FOOTER_BLOG.PARTNERS_LABEL`,
    legal: t`FOOTER_BLOG.BOTTOM_LINKS.LEGAL_LABEL`,
    privacy: t`FOOTER_BLOG.BOTTOM_LINKS.PRIVACY_POLICY_LABEL`,
    cookies: t`FOOTER_BLOG.BOTTOM_LINKS.COOKIES_LABEL`,
    personalInfo: t`FOOTER_BLOG.BOTTOM_LINKS.PERSONAL_INFO_LABEL`,
    cookiesSetting: t`FOOTER_BLOG.BOTTOM_LINKS.COOKIES_SETTINGS_LABEL`,
    copyrightLabel: t(`FOOTER_BLOG.COPYRIGHT_LABEL`, { current_year: year }),
    language: t`FOOTER_BLOG.LANGUAGE_LABEL`,
    english: t`FOOTER_BLOG.ENGLISH_LABEL`,
    french: t`FOOTER_BLOG.FRENCH_LABEL`,
  };

  return (
    <footer
      className="b-bg-black b-px-3 b-pt-2 lg:b-px-0 lg:b-pt-0"
      data-test-id="footer">
      <FooterPage
        links={footerLinks}
        categories={categories}
        lang={localizedText}
        showBlockMore={false}
        logoFooter={true}
        hidePartners={true}
        bottomSpacing="small"
        customLanguages={['en', 'fr']}
      />
    </footer>
  );
};

export default Footer;
