const GREENHOUSE_JOB_DETAIL = `${process.env.GREENHOUSE_BASE_URL}/jobs/[id]`;
const fetchData = async url => {
  return fetch(url, { cache: 'no-cache' })
    .then(res => {
      if (res.status === 404) {
        return {};
      } else {
        return res.json();
      }
    })
    .catch(err => {
      console.warn(err);
      return {};
    });
};

export async function getPosition(positionId) {
  const url = GREENHOUSE_JOB_DETAIL.replace('[id]', positionId);
  const data = await fetchData(url);
  return { ...data };
}

/**
 * Function that requests all department list
 * @returns Department list
 */
export async function getDepartments() {
  const data = await fetchData(
    `${process.env.GREENHOUSE_BASE_URL}/departments`,
  );
  return { ...data };
}

/**
 * Function that requests all open positions
 * @returns Job list
 */
export async function getAllJobs() {
  const data = await fetchData(`${process.env.GREENHOUSE_BASE_URL}/jobs`);
  return { ...data };
}
/**
 * Function that requests a department
 * @returns Department
 */
export async function getDepartment(departmentId) {
  const data = await fetchData(
    `${process.env.GREENHOUSE_BASE_URL}/departments/${departmentId}`,
  );
  return { ...data };
}

/**
 * Function that request a list of all locations.
 * @returns Location list
 */
export async function getLocations() {
  const data = await fetchData(`${process.env.GREENHOUSE_BASE_URL}/offices`);
  return { ...data };
}
