import sanitizeHtml from 'sanitize-html';

export const buildSlug = str =>
  str
    .toLowerCase()
    .replace(/[^\w -]+/g, '')
    .replace(/ +/g, '-');

export const KeyCodes = Object.freeze({
  SPACE: 0,
  DOWN: 40,
  ENTER: 13,
  ESCAPE: 27,
  UP: 38,
});

export function getDatalayerInitialArgs(locale) {
  return {
    gtmId: process.env.NEXT_PUBLIC_GTM_ID,
    dataLayer: {
      event: 'dataLayer-initialized',
      user: {
        user_unity_id: 'undefined',
        user_logged_in: 'no',
      },
      environment: {
        environment_locale: locale,
        environment_currency: 'undefined',
      },
    },
    dataLayerName: 'dataLayer',
  };
}

export function getDatalayerArgs(
  pagePath,
  title,
  event = 'userEvent',
  event_name = 'pageView',
) {
  return {
    dataLayer: {
      event,
      event_name,
      properties: {
        pagePath,
        title,
      },
    },
    dataLayerName: 'dataLayer',
  };
}

export function getLanguage(locale) {
  const mapping = {
    'en-US': 'en',
    cn: 'zh-hans',
    pt: 'pt-br',
    kr: 'ko',
  };
  return mapping[locale] ? mapping[locale] : locale;
}
