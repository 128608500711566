import React, {
  useState,
  useMemo,
  useEffect,
  createContext,
  useContext,
} from 'react';
import { parseCookies } from 'nookies';
import PropTypes from 'prop-types';

// Create context.
const context = createContext();

/**
 * OneTrust Global Provider.
 * @param children
 * @returns {JSX.Element}
 * @constructor
 */
function OneTrustProvider({ children }) {
  const [oneTrustCookieState, dispatch] = useState({
    oneTrustCookie: {},
    groups: {},
    categories: {},
  });

  // List all ids for cookie buttons.
  const idButtonsList = [
    'onetrust-accept-btn-handler',
    'onetrust-reject-all-handler',
    'onetrust-pc-btn-handler',
    'ot-pc-refuse-all-handler',
    'save-preference-btn-handler',
    'accept-recommended-btn-handler',
  ];

  // Parse cookie and build context.
  const cookieConsent = (state, dispatcher) => {
    const oneTrustState = state;
    // Default match values.
    const cookieCategories = {
      targetingCategory: 'C0004:1',
      functionalCategory: 'C0003:1',
    };

    // Load cookie.
    const optanonConsent = parseCookies(null).OptanonConsent;

    if (optanonConsent) {
      Array.prototype.slice
        .call(optanonConsent.split('&'))
        .map(subCookieValue => {
          const value = subCookieValue.split('=');
          // Some properties might be empty.
          if (value[1]) {
            oneTrustState.oneTrustCookie[value[0]] = value[1];
          } else {
            oneTrustState.oneTrustCookie[value[0]] = '';
          }
          return subCookieValue;
        });

      if (oneTrustState.oneTrustCookie.groups) {
        oneTrustState.groups = oneTrustState.oneTrustCookie.groups;
        Object.entries(cookieCategories).forEach(child => {
          const category = child[1].split(':');
          if (oneTrustState.groups.match(child[1])) {
            oneTrustState.categories[category[0]] = true;
          } else {
            oneTrustState.categories[category[0]] = false;
          }
        });
      }
    }

    // Update context value.
    dispatcher({ ...oneTrustState, type: 'update' });
  };

  useEffect(() => {
    // Set initial context value based on available cookies.
    cookieConsent(oneTrustCookieState, dispatch);

    // Global click event handler.
    const clickEvent = event => {
      // Check the element id.
      const intersection = Array.prototype.slice
        .call(event.target.classList)
        .filter(x => idButtonsList.includes(x));
      if (intersection.length > 0 || idButtonsList.includes(event.target.id)) {
        // Update the context.
        cookieConsent(oneTrustCookieState, dispatch);
      }
    };

    // Set global event click listener.
    window.addEventListener('click', clickEvent);
    return () => window.removeEventListener('click', clickEvent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const contextValue = useMemo(() => {
    return { oneTrustCookieState, dispatch };
  }, [oneTrustCookieState, dispatch]);

  return <context.Provider value={contextValue}>{children}</context.Provider>;
}

OneTrustProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.element])
    .isRequired,
};

const useOneTrustContext = () => useContext(context);

export { OneTrustProvider, useOneTrustContext };
