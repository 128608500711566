
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import Proptypes from 'prop-types';
import Head from 'next/head';
import getConfig from 'next/config';
import TagManager from 'react-gtm-module';
import '@unity-technologies/web-ui-typeface';
import '@unity-technologies/web-ui-components/styles.css';
import '@unity-technologies/web-ui-components/header/styles.css';
import { buildHeadTitle, buildFavicon } from '../lib/build-component';
import Footer from '../components/Footer';
import GlobalHeader from '../components/Header';
import '../styles/globals.css';
import { OneTrustProvider } from '../components/context/useOneTrustContext';
import 'material-icons/iconfont/material-icons.css';
import { getDatalayerInitialArgs } from '../lib/utils';

function App({ Component, pageProps }) {
  const router = useRouter();
  useEffect(() => {
    TagManager.initialize(getDatalayerInitialArgs(router.locale));

    if (typeof window !== 'undefined') {
      window.OptanonWrapper = () => {};
    }
  }, []);

  return (
    <>
      <Head>
        <script
          src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
          charSet="UTF-8"
          data-domain-script={process.env.NEXT_PUBLIC_ONETRUST_DOMAIN_ID}
          data-dLayer-ignore="true"
          data-document-language="true"
          type="text/javascript"
          async
        />
        {buildHeadTitle(pageProps.entity)}
        {buildFavicon()}
        <meta
          property="og:title"
          content={pageProps.ogTitle ? pageProps.ogTitle : 'Unity Careers'}
        />
        <meta
          property="og:description"
          content={pageProps.ogDescription ? pageProps.ogDescription : ''}
        />
        <meta
          property="og:image"
          content={
            pageProps.ogImage
              ? process.env.NEXT_PUBLIC_URL_APP + pageProps.ogImage
              : `${process.env.NEXT_PUBLIC_URL_APP}/og/ogimg.jpg`
          }
        />
      </Head>
      <OneTrustProvider>
        {pageProps.header && <GlobalHeader {...pageProps.header} />}
        <Component {...pageProps} />
        <Footer
          footerLinks={pageProps.footerLinks || []}
          navLinks={pageProps.subNavMenu || []}
        />
      </OneTrustProvider>
    </>
  );
}

const __Page_Next_Translate__ = App;
App.propTypes = {
  Component: Proptypes.oneOfType([Proptypes.object, Proptypes.func]).isRequired,
  pageProps: Proptypes.oneOfType([Proptypes.object, Proptypes.func]).isRequired,
};


    export default __appWithI18n(__Page_Next_Translate__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: (lang, ns) => import(`@unity-technologies/web-module-js-translations/locales/${{en:"en-US", fr: "fr-FR"}[lang]}/${ns}.json`).then(m => m.default),
    });
  