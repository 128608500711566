import React from 'react';
import PropTypes from 'prop-types';
import FAQSection from './components/FAQSection';

function FAQ({ title, description, sections }) {
  return (
    <section>
      {description && (
        <div className="c-mb-16">
          <h2 className="c-text-h-lg c-mb-9 c-md:mb-10">{title}</h2>
          <div>{description}</div>
        </div>
      )}

      <div className="c-flex c-flex-col md:c-flex-row c-w-full">
        {sections.map(section => (
          <FAQSection
            title={section.title}
            questions={section.questions}
            key={section.id}
          />
        ))}
      </div>
    </section>
  );
}

export default FAQ;

FAQ.defaultProps = {
  title: '',
  description: '',
};

FAQ.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string,
      questions: PropTypes.arrayOf(
        PropTypes.shape({
          question: PropTypes.string.isRequired,
          answer: PropTypes.string.isRequired,
        }),
      ).isRequired,
    }),
  ).isRequired,
};
