// This is workaround from the official issue https://github.com/aralroca/next-translate/issues/851 and we should upgrade to NexJS 13.1.x in the meantime we use this workaround.
const nextTranslation = require('next-translate/lib/cjs/plugin/utils.js');

// Add aliases for the locale files here
// ex: en --> `@unity-technologies/web-module-js-translations/locales/en-US/blog.json
nextTranslation.defaultLoader =
  '(lang, ns) => import(`@unity-technologies/web-module-js-translations/locales/${{en:"en-US", fr: "fr-FR"}[lang]}/${ns}.json`).then(m => m.default)';

module.exports = {
  locales: ['en', 'fr'],
  defaultLocale: 'en',
  loader: true,
  pages: {
    '*': ['blog', 'careers'],
  },
};
