import PropTypes from 'prop-types';
import FAQItem from './FAQItem';

function FAQSection({ title, questions }) {
  return (
    <div className="c-mb-8 c-w-full c-px-4 c-md:w-1/2">
      {title && <h3 className="c-text-h-md c-mb-8">{title}</h3>}

      {questions.map(question => (
        <FAQItem
          question={question.question}
          answer={question.answer}
          key={question.question}
        />
      ))}
    </div>
  );
}

export default FAQSection;

FAQSection.propTypes = {
  title: PropTypes.string.isRequired,
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      question: PropTypes.string.isRequired,
      answer: PropTypes.string.isRequired,
    }),
  ).isRequired,
};
