import SubNav from '@unity-technologies/web-ui-components/components/SubNav/SubNav';
import Heading from '@unity-technologies/web-ui-components/components/Heading/Heading';
import FAQ from '../components/Faq/FAQ';
import sanitizeHtml from 'sanitize-html';
import Link from 'next/link';
import { getInfoDeparments, getMapMarkers } from './dataService';
import { find } from 'lodash';
import Location from '../components/Location';
import useTranslation from 'next-translate/useTranslation';

export const buildHeadTitle = title => {
  return <title>{title}</title>;
};
export const buildFavicon = () => {
  const FAVICON_VERSION = 2;

  return (
    <>
      <link
        rel="icon"
        href={`../../static/assets/favicon.ico?v=${FAVICON_VERSION}`}
        sizes="any"
        // 16 and 32 px
      />
      <link
        id="favicon-svg"
        rel="icon"
        href={`../../static/assets/favicon.svg?v=${FAVICON_VERSION}`}
        type="image/svg+xml"
      />
      <link
        rel="apple-touch-icon"
        href={`../../static/assets/apple-touch-icon.png?v=${FAVICON_VERSION}`}
        // 180px
      />
      <link rel="manifest" href="../../static/assets/site.webmanifest" />
    </>
  );
};
export function getUnescapeHtmlDiv(content, transformTags = {}) {
  const previewMarkup = {
    __html: sanitizeHtml(content, { transformTags, allowedAttributes: false }),
  };
  return <div dangerouslySetInnerHTML={previewMarkup} />;
}

export const buildHeading = ({ level, title, className }) => (
  <Heading className={className} level={level}>
    {getUnescapeHtmlDiv(title)}
  </Heading>
);

export const buildDepartmentName = (department, t) => {
  const departments = getInfoDeparments(t);
  return (
    <h2 className="c-text-2xl c-font-semibold">
      {departments[department]?.title}
    </h2>
  );
};

export const buildDepartmentNameNoHtml = (department, t) => {
  const departments = getInfoDeparments(t);
  return <>{departments[department]?.title}</>;
};

export const buildDepartmentDescription = (department, t) => {
  const departments = getInfoDeparments(t);
  return (
    <>
      <strong>{departments[department]?.subtitle}</strong>
      <p>{departments[department]?.description}</p>
    </>
  );
};

export const BuildGridList = (items, type = 'location') => {
  const { t } = useTranslation('careers');
  return items
    .filter(item => item.count > 0)
    .sort((a, b) => a.label.localeCompare(b.label))
    .map(item => {
      let link = '';
      if (type === 'location') {
        link = `/location/${item.id}`;
      }
      if (type === 'department') {
        link = `/find-position?department=${item.id}`;
      }
      return (
        <Link href={link} key={item.id}>
          <div className="c-p-4 c-pl-0 c-border-b c-border-b-cheap hover:c-text-blue cursor-pointer">
            {type === 'location' && (
              <h3 className="c-text-2xl c-font-semibold">{item.label}</h3>
            )}
            {buildDepartmentName(item.id, t)}
            <p>
              {item.count} {t`POSITIONS`}
            </p>
          </div>
        </Link>
      );
    });
};

export const buildMapMarkers = locations => {
  const markers = getMapMarkers();

  return markers.map(mark => {
    const location = find(locations, { id: mark.key });
    return (
      location &&
      location.count > 0 && (
        <Location
          key={mark.key}
          id={mark.key}
          top={mark.top}
          left={mark.left}
          name={mark.name}
          count={location.count}
        />
      )
    );
  });
};

export const buildFAQ = sections => {
  return <FAQ sections={sections} />;
};
