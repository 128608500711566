import PropTypes from 'prop-types';
import { useState } from 'react';
import classNames from 'classnames';
import Chevron from '../assets/chevron-down.svg';
import { KeyCodes } from '../../../lib/utils';
import { getUnescapeHtmlDiv } from '../../../lib/build-component';

function FAQItem({ question, answer }) {
  const [showAnswer, setShowAnswer] = useState(false);

  const handleKeyPress = event => {
    if (event.keyCode === KeyCodes.ENTER || event.keyCode === KeyCodes.SPACE) {
      setShowAnswer(!showAnswer);
    }
  };

  const handleItemOnClick = () => {
    setShowAnswer(!showAnswer);
  };

  const chevronClasses = classNames(
    `c--ml-1 c-h-full c-text-blue c-transition-transform c-duration-200 
    c-cursor-pointer c-hover:scale-110 c-hover:drop-shadow-lg c-transform`,
    {
      'c--rotate-90': !showAnswer,
    },
  );

  return (
    <div className="c-mb-4">
      <div className="c-flex c-items-center">
        <span className="c-w-[25px] c-h-[25px]">
          <Chevron className={chevronClasses} onClick={handleItemOnClick} />
        </span>
        <span
          role="button"
          tabIndex={0}
          className="c-text-h-xxs c-font-bold c-ring-blue-dark c-mx-3 c-cursor-pointer  c-rounded-lg c-ring-offset-4 c-hover:underline c-focus:outline-none c-focus:ring-4"
          onClick={handleItemOnClick}
          onKeyPress={handleKeyPress}>
          <h4>{question}</h4>
        </span>
      </div>
      {showAnswer &&
        getUnescapeHtmlDiv(answer, {
          a(tagName, attribs) {
            return {
              tagName,
              attribs: {
                ...attribs,
                class: 'c-text-blue',
              },
            };
          },
          div(tagName, attribs) {
            return {
              tagName,
              attribs: {
                class: 'c-ml-6 c-mt-2',
              },
            };
          },
        })}
    </div>
  );
}

export default FAQItem;

FAQItem.propTypes = {
  question: PropTypes.string.isRequired,
  answer: PropTypes.string.isRequired,
};
